<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(id)="{ item }">
        <p @click="editVenue(item)" class="m-0 underline">
          {{ item.id }}
        </p>
      </template>
      <template #cell(name)="{ item }">
        <p class="m-0">
          {{ item.name }}
        </p>
      </template>
      <template #cell(provinceName)="{ item }">
        <p class="m-0">
          {{ item.provinceName }}
        </p>
      </template>
      <template #cell(venueAddress)="{ item }">
        <p class="m-0">
          {{ item.venueAddress }}
        </p>
      </template>
      <template #cell(isActive)="{ item }">
        <p
          class="m-0"
          :class="item.isActive == true ? 'color-green' : 'color-red'"
        >
          {{ item.isActive == true ? "Active" : "Inactive" }}
        </p>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="container">
          <font-awesome-icon
            class="mr-4 pointer"
            icon="pen"
            @click="editVenue(item)"
          />
          <font-awesome-icon
            class="mr-4 pointer"
            icon="calendar"
            @click="$router.push(`/branch/detail/${item.eventId}/schedule`)"
          />

          <b-form-checkbox
            v-model="item.isActive"
            class="pointer"
            switch
            :value="true"
            :unchecked-value="false"
            @change="onChangeStatus(item)"
          />
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template></b-table
    >
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  name: "BranchTable",
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    editVenue(val) {
      const { id } = val;
      this.$emit("editVenue", id);
    },
    onChangeStatus(val) {
      this.$emit("onChangeStatus", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-red {
  color: #dc3545;
}

.color-green {
  color: #28a745;
}
.container {
  min-height: 100px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
